<template>
  <div class="scene3">
    <scene3 @onDone="onDone"></scene3>
    <transition name="fade">
      <div class="boxsss" v-show="showUIName !== ''">
        <component :is="showUIName"></component>
      </div>
    </transition>
    <div class="lottie" v-if="loadingAn">
      <lottie-web></lottie-web>
    </div>
    <LargeVideo
      v-if="isShowVideo"
      :videoSrc="video1"
      ref="videoScene3Ref"
    ></LargeVideo>
  </div>
</template>
<script>
import scene3 from "./../components/three-scene-3/index.vue";
import TopVideo from "./components/top-video.vue";
import HuBu from "./components/hu-bu.vue";
import HuYong from "./components/hu-yong.vue";
import JieShao from "./components/jie-shao.vue";
import YinJian from "./components/yin-jian.vue";
import WeSeeds from "./components/we-seeds.vue";
import LargeVideo from "./components/large-video.vue";
import mitt from "../lib/bus";
export default {
  components: {
    scene3,
    TopVideo,
    HuBu,
    HuYong,
    JieShao,
    YinJian,
    WeSeeds,
    LargeVideo,
  },
  data() {
    return {
      isCloseWs: false,
      loadingAn: true,
      isShowVideo: false,
      video1: "",
      socket: "",
      showUIName: "",
      devMap: {
        closeVideo: {
          camMap: null,
          route: null,
          UI: "keep",
          video: {
            type: "close",
          },
        },
        playVideo: {
          camMap: null,
          route: null,
          UI: "keep",
          video: {
            type: "play",
          },
        },
        pauseVideo: {
          camMap: null,
          route: null,
          UI: "keep",
          video: {
            type: "pause",
          },
        },
        openSeedVideo: {
          camMap: null,
          UI: "keep",
          video: {
            type: "open",
            src: process.env.VUE_APP_BASE_ASSETS+ "assets/video/weseed.mp4",
          },
          route: null,
        },
        openZxVideo: {
          camMap: null,
          UI: "keep",
          video: {
            type: "open",
            src: process.env.VUE_APP_BASE_ASSETS+ "assets/video/zxtj.mp4",
          },
          route: null,
        },
        returnMain3: {
          camMap: "重置视角",
          UI: null,
          route: null,
        },
        toScene1: {
          camMap: null,
          UI: null,
          route: "scene1",
        },
        toMain1: {
          camMap: null,
          UI: null,
          route: "scene1",
        },
        toZxtj1: {
          camMap: "",
          UI: "TopVideo",
          route: null,
        },
        toZxtj2: {
          camMap: "",
          UI: "HuBu",
          route: null,
        },
        toYjjs1: {
          camMap: "",
          UI: "HuYong",
          route: null,
        },
        toYjjs2: {
          camMap: "",
          UI: "JieShao",
          route: null,
        },
        toYjjs3: {
          camMap: "",
          UI: "YinJian",
          route: null,
        },
        toWS: {
          camMap: "",
          UI: "WeSeeds",
          route: null,
        },
        toScene2: {
          camMap: null,
          UI: null,
          route: "scene2",
        },
      },
    };
  },
  // mounted() {
  //   let _this=this
  //   setTimeout(() => {
  //     mitt.on("sod",(data)=>{
  //     // console.log(_this);
  //     _this.wsMessage({data})
  //   })
  //   }, 1000);
  // },
  methods: {
    onDone() {
      this.loadingAn = false;
      this.$emit("loadend");
      let _this = this
      setTimeout(() => {
        mitt.on("sod", (data) => {
          // console.log(_this);
          _this.wsMessage({ data })
        })
      }, 1000);
    },
    initWebsocket() {
      let wsUrl = process.env.VUE_APP_BASE_WSURL;
      this.socket = new WebSocket(wsUrl);
      this.socket.onopen = this.wsOpen;
      this.socket.onclose = this.wsClose;
      this.socket.onerror = this.wsError;
      this.socket.onmessage = this.wsMessage;
    },
    wsOpen() {
      console.log("打开webso");
    },
    wsClose() {
      console.log("关闭ws");
      if (!this.isCloseWs) {
        this.initWebSocket();
      }
    },
    wsError(e) {
      console.log("错误", e);
    },
    handleOrder(dev) {
      let { camMap, UI, route, video } = this.devMap[dev];
      if (route) {
        this.$router.push({ name: route });
      }
      if (camMap) {
        let where = camMap;
        // bus.emit("scene-1-cameraAnima", where, 2);
      }
      if (UI) {
        if (UI !== "keep") {
          this.showUIName = UI;
        }
      } else {
        this.showUIName = "";
      }
      if (video) {
        if (video.type == "open") {
          this.video1 = video.src;
          this.isShowVideo = true;
        } else if (video.type == "close") {
          this.isShowVideo = false;
        } else if (video.type == "pause") {
          this.$refs.videoScene3Ref.pauseVideo();
        } else if (video.type == "play") {
          this.$refs.videoScene3Ref.playVideo();
        }
      }
    },
    wsMessage({ data }) {
      try {
        let message = JSON.parse(data);
        if (message.scene.includes("scene3")) {
          this.handleOrder(message.deactive);
        }
      } catch (e) {
        console.log("错误捕获", e);
      }
    },
  },
  unmounted() {
    // this.isCloseWs = true;
    // this.socket.close();
    mitt.off("sod")
  },
};
</script>
<style lang="less" scoped>
.scene3 {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.lottie {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
