export const viewMap = {
    WE平台:{
        cx:140.16954008604944, cy:135.9484332178547, cz:113.27423505727687, tx:-30.404250127248773, ty:27.38661787166491, tz:83.17178902847398
      },
      工商业:{
        cx:259.4387781013323, cy:149.65730915437442, cz:43.53068161114571, tx:71.12647461820767, ty:-23.90086348452781, tz:-21.341206279046602
      },
      农贸市场:{
        cx:182.37984543646834, cy:109.76716150868037, cz:-28.00057590304357, tx:54.592692606486615, ty:2.7664819002207053, tz:-107.10347509270578
      },
      政府机关:{
        cx:20.212466233553727, cy:171.18793659864684, cz:105.74068515062275, tx:-131.82924961460841, ty:53.728073167470036, tz:8.848212145092058
      },
      医院:{
        cx:-1.8898496604692525, cy:145.62292217586736, cz:271.9501558928332, tx:-193.84255626453663, ty:-8.586382280295808, tz:176.21106451510545
      },
      公共建筑:{
        cx:-213.42318276145753, cy:186.10111819889786, cz:147.94391444037322, tx:-378.585158601486, ty:25.393890874904706, tz:86.78025242838291
      },
      消防大队:{
        cx:273.7596216690386, cy:163.7273112124973, cz:224.64681249577052, tx:-135.35060913498597, ty:-235.67195042622072, tz:41.89254767286708
      },
      学校:{
        cx:220.91425258675164, cy:180.26033033820954, cz:313.16754078390005, tx:-31.494852501421327, ty:-13.601240868671237, tz:163.6947371250684
      }
}