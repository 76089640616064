<style lang="less" scoped>
.yin-jian {
  width: 576px;
  height: 462px;
  background-image: url("../../assets/top/yin-jian.png");
  background-size: 100%;
  margin: 64px 48px;
}
.bu-num {
  margin-left: 599px;
  position: absolute;
  bottom: 23px;
  font-size: 16px;
  font-weight: 400;
  color: #424c5e;
  line-height: 22px;
}
</style>
<template>
  <custom-box :boxHeight="750"
    ><div class="yin-jian"></div>
    <div class="bu-num">3/3</div>
  </custom-box>
</template>
<script>
export default {};
</script>
