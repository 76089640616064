<template>
  <custom-box>
    <img style="width:100%" v-if="isCn" :src="'./assets/images/top-seed1.png'" alt="">
    <img style="width:100%" v-else :src="'./assets/images/top-seed1-en.png'" alt="">
  </custom-box>
</template>

<script>

export default{
}
</script>